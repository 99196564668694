import '../sass/project.scss';
import $ from 'jquery'
import jQuery from 'jquery'
import * as coreui from '@coreui/coreui-pro';
import * as toastr from "toastr";
import '@coreui/chartjs'
import 'select2';
import 'jquery-ui-dist/jquery-ui.js';
import 'select2/dist/css/select2.css';
import './jquery.formset.js';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.nl.min.js';

import './calendar'


document.querySelectorAll('[data-toggle="tooltip"]').forEach(function (element) {
    new coreui.Tooltip(element);
});

toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

(function () {
    'use strict';
    $('.invalid-feedback').fadeIn();

    $('.selectmultiple, .select, .gemeentewidget').select2({
        theme: 'coreui',
    });

    $("#id_atsp, #id_info-atsp, #id_aansluitnummer-atsp").on('select2:select', function (e) {
        $("#aansluitnummer-atsp").text($(this).val())
    });

    $("#id_gemeente, #id_info-gemeente").on('select2:select', function (e) {

        var value = $(this).select2({theme: "coreui"}).find(":selected").data("regio")

        $("#aansluitnummer-regio").text(value)
    });

    function formatRepo(repo) {
        if (repo.loading) {
            return repo.text;
        }
        console.log(repo)
        var $container = $(
            "<div class='select2-result-repository clearfix'>" +
            "" + repo.naam + " (" + repo.aansluitnummer + ")" +
            "</div>" +
            "</div>" +
            "</div>"
        );

        $container.find(".select2-result-repository__title").text(repo.naam);

        return $container;
    }

    function formatRepoSelection(repo) {

        const url = document.getElementById("aansluiting_autocomplete_url")

        if (repo.id) {
            if (url) {
                window.location = url.value + repo.id
            } else {
                window.location =
                    "/aansluitingen/" + repo.id
            }
        }
        return repo.naam || repo.text;
    }

    $(".aansluiting_autocomplete").select2({
        theme: 'coreui',
        inline: true,
        search: true,
        selection: false,
        searchPlaceholder: 'Search',
        ajax: {
            url: '/api/aansluitingen/search',
            dataType: 'json',
            processResults: function (data, params) {
                return {
                    results: data,
                };
            },
            // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
        },
        placeholder: 'Aansluitingen zoeken op naam of aansluitnummer',
        minimumInputLength: 3,
        templateResult: formatRepo,
        templateSelection: formatRepoSelection,

    });

    var bag_search_query = $('#id_bag_search-bag_search_query');
    var bag_result = $("#bag-result");


    function suggest(preselect) {
        var val = bag_search_query.val()

        if (val && val.length > 3) {
            $.ajax(
                'https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest',
                {
                    data: {'q': val + " and type:adres", 'fq': 'bron:BAG'}
                }
            ).done(function (res) {

                var results = [];

                bag_result.html("")

                $.each(res.highlighting, function (index, value) {

                    var html = '<div className="form-check">\
                        <input className="form-check-input" type="radio" name="bag_search-bag_lookup_id" id="' + index + '"\
                               value="' + index + '">\
                            <label className="form-check-label" for="' + index + '">\
                                ' + value.suggest[0] + '\
                            </label>\
                    </div>'

                    var div = $("<li />")
                    div.addClass("list-group-item")
                    div.data("id", index)
                    div.html(html)
                    div.attr("style", 'cursor: pointer;')
                    bag_result.append(div)
                });

                var html = '<div className="form-check">\
                        <input className="form-check-input" type="radio" name="bag_search-bag_lookup_id" id="bag_lookup_id_no_result" value="None">\
                            <label className="form-check-label" for="bag_lookup_id_no_result">\
                                Adres niet gevonden\
                            </label>\
                    </div>'

                var div = $("<li />")
                div.addClass("list-group-item")
                div.html(html)
                div.attr("style", 'cursor: pointer;')
                bag_result.append(div)

                if (preselect) {
                    let x = document.getElementById("bag_lookup_id_preselect").value
                    console.log(x)
                    $("#" + x).prop("checked", true)
                }
            }).fail(function (jqXHR, textStatus) {
                console.log(textStatus);
                console.log(jqXHR);
                alert("Foutmelding bij raadplegen van de locatieserver. Is het domein https://geodata.nationaalgeoregister.nl/ bereikbaar? Neem contact op met uw contactpersoon als dit probleem zich blijft voordoen.");
            });
        }
    }

    suggest(true)

    bag_search_query.on('input', function () {
        suggest()
    });

    var elms = $(".final-value")
    jQuery.each(elms, function (i, val) {

        var prev = $(val).prev()

        var td = $(val).find("td")[1]

        var txt_0 = $(td).text().replace(/\s/g, '');
        var txt_1 = $(prev.find("td")[1]).text().replace(/\s/g, '');

        if (txt_0 == txt_1) {
            console.log("gelijk", txt_0)
            $(val).hide()
        } else {
            console.log("ongelijk", txt_0, txt_1)
            $(td).addClass("font-italic")
        }
    })

    $('.dateinput, .datetimeinput, .datepicker').datepicker({language: 'nl'});

})();


let meldingChart = document.getElementById('melding-chart');
let meldingChartData = document.getElementById('melding-chart-data');

if (meldingChart !== null && meldingChartData !== null) {

    var ctx = meldingChart.getContext('2d');
    let text = meldingChart.dataset.chartdata;


    var chart = new Chart(ctx, {
        data: JSON.parse(meldingChartData.textContent),
        type: "bar",
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    }
                }]
            }
        },
    });
}

let x = document.getElementsByClassName("toastr")

for (const y of x) {
    if (y.dataset.toastrType == "success") {
        toastr.success(y.textContent, y.dataset.toastrTitle);
    } else if (y.dataset.toastrType == "warning") {
        toastr.warning(y.textContent, y.dataset.toastrTitle);
    } else if (y.dataset.toastrType == "error") {
        toastr.error(y.textContent, y.dataset.toastrTitle);
    } else {
        toastr.info(y.textContent, y.dataset.toastrTitle);
    }
}

$('.formset_row-telefoonnummers').formset({
    'addCssClass': 'btn btn-default',
    addText: '<i class="fas fa-plus"></i> Telefoonnummer',
    deleteText: '',
    prefix: 'telefoonnummers',
});


var element = '#sortable1, #sortable2';
var handle = '.card-header';
var connect = '.connectedSortable';
$(element).sortable({
    connectWith: connect,
    tolerance: 'pointer',
    forcePlaceholderSize: true,
    opacity: 0.8,
    placeholder: 'list-group-item list-group-item-accent-secondary'

}).disableSelection();


$("#sortable2").sortable({
    receive: function (event, ui) {
        // so if > 10
        if ($(this).children().length > 4) {
            alert("Er kunnen maximaal 4 waarschuwingsadressen per bouwdeel aangegeven worden.")
            //ui.sender: will cancel the change.
            //Useful in the 'receive' callback.
            $(ui.sender).sortable('cancel');
        }
    }
});

$("#sortable2").sortable({
    update: function () {
        var result = $('#sortable2').sortable('toArray');

        var hiddenInputs = $("#hidden-inputs")

        hiddenInputs.empty()
    }
})


$('#exampleModal').on('shown.coreui.modal', function (e) {
    dotGraph()
})

function dotGraph() {
    // Parse the DOT syntax into a graphlib object.

    var content = document.getElementById('dot-data').textContent

    var g = graphlibDot.read(content)

    g.nodes().forEach(function (v) {
        var node = g.node(v);
        // Round the corners of the nodes
        node.rx = node.ry = 5;
    });

    g.edges().forEach(function (v) {
        var node = g.edge(v);
        // Round the corners of the nodes
        node.curve = d3.curveMonotoneY
    });

    // Render the graphlib object using d3.
    var renderer = dagreD3.render();
    d3.select("svg g").call(renderer, g);


    // Optional - resize the SVG element based on the contents.
    var svg = document.querySelector('#graphContainer');
    var bbox = svg.getBBox();
    svg.style.width = bbox.width + 0 + "px";
    svg.style.height = bbox.height + 0 + "px";
}


let progress = 0
let max = 30
let progressBar = document.getElementById("progress-bar")
let stopButton = document.getElementById("stop-refresh")

if (stopButton != null) {
    stopButton.addEventListener("click", function () {
        clearInterval(refreshInterval)
    });
    let refreshInterval = setInterval(function () {
        progress += 1
        progressBar.style["width"] = ((progress / max) * 100).toString() + "%";

        console.log(progress)

        if (progress == max + 5) {
            clearInterval(refreshInterval)

            let x = window.location.href.split('?')
            let y = x[1].split("#")

            window.location.href = x[0] + "?t=" + new Date().getTime() + "#" + y[1]
        }
    }, 100);
}


const apiKeyModelElm = document.getElementById('api-key-modal');
if (apiKeyModelElm != null) {
    var apiKeyModel = new coreui.Modal(apiKeyModelElm, {
        keyboard: false,
        backdrop: 'static'
    })

    apiKeyModel.toggle();
}


$(window).on('load', function () {

    const afsluitingZaakType = document.getElementById('id_type');
    const div = $("#div_id_passief_stelling_tot");

    console.log(afsluitingZaakType)

    if (afsluitingZaakType != null) {

        if (afsluitingZaakType.value == 'afsluiting') {
            div.fadeOut();
        } else {
            div.fadeIn();
        }

        $(afsluitingZaakType).on('select2:select', function (event) {
            let value = event.params.data.id

            if (value == "afsluiting") {
                div.fadeOut();
            } else {
                div.fadeIn()
            }
        });
    }

    const migratieGestartElm = document.getElementById('migratie-gestart');

    if (migratieGestartElm != null) {
        var myModal = new coreui.Modal(migratieGestartElm, {
            keyboard: false
        })
        myModal.show()
    }

    let toggle = document.getElementById("switch-todo")
    if (toggle != null) {
        if (toggle.checked === false) {
            let x = $('.todo').collapse('toggle')
        }
    }
});
