import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import 'fullcalendar/main.css';
import axios from 'axios';

document.addEventListener('DOMContentLoaded', function () {
    var calendarEl = document.getElementById('fullcalendar');

    if (calendarEl !== null) {
        var calendar = new Calendar(calendarEl, {
            plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
            themeSystem: 'bootstrap',
            initialView: 'dayGridMonth',
            firstDay: 1,
            locale: 'nl',
            events: function (info, successCallback, failureCallback) {
                axios.get(
                    "/api/afspraken",
                    {params: {start_tijd: info.start, eind_tijd: info.end, limit: 1000}}
                ).then(
                    ({data}) => {
                        successCallback(data['results'])
                    }
                ).catch(function (error) {
                    toastr.error("Het ophalen van de afspraken is mislukt. Probeer het met een paar minuten nog eens of neem contact op met uw contactpersoon.");
                });
            }
        });
        calendar.render();
    }
});
